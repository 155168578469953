import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Keycloak, { KeycloakProfile } from 'keycloak-js';
import { Observable, Subject, catchError, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IOntology } from '../interfaces/i-ontology';

@Injectable({
  providedIn: 'root',
})
export class KeycloakService {
  private keycloakAuth: Keycloak | undefined;
  private userProfile: KeycloakProfile | undefined;
  private refreshTokenInProgress = false;
  private tokenRefreshedSource = new Subject();
  private tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

  init(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.keycloakAuth = new Keycloak(environment.keycloak);
      this.keycloakAuth
        .init({
          onLoad: 'login-required',
          checkLoginIframe: false,
        })
        .then(() => {
          this.keycloakAuth
            ?.loadUserProfile()
            .then(profile => {
              this.userProfile = profile;
              resolve();
            })
            .catch(e => {
              console.error(e);
              console.log('Cannot load user profile');
              reject();
            });
        })
        .catch(() => {
          reject();
        });
    });
  }

  logout() {
    this.keycloakAuth?.logout({
      redirectUri: location.origin,
    });
  }

  addAuthHeader(request: HttpRequest<unknown>): HttpRequest<unknown> {
    const authToken = this.getToken() || '';
    return request.clone({
      setHeaders: {
        Authorization: 'Bearer ' + authToken,
      },
    });
  }

  userHasUIAccess(): boolean | undefined {
    return this.keycloakAuth?.hasResourceRole('Access_UI');
  }

  canManageOntologies(): boolean | undefined {
    return this.keycloakAuth?.hasResourceRole('Manage_ontologies');
  }

  canManageOntology(ontology: IOntology): boolean | undefined {
    return this.canManageOntologies() || ontology.created_by === this.userProfile?.email;
  }

  getToken(): string | undefined {
    return this.keycloakAuth?.token;
  }

  isTokenExpired(): boolean | undefined {
    return this.keycloakAuth?.isTokenExpired();
  }

  refreshToken(): Observable<unknown> {
    if (this.refreshTokenInProgress) {
      return new Observable(observer => {
        this.tokenRefreshed$.subscribe(value => {
          observer.next(value);
          observer.complete();
        });
      });
    } else {
      this.refreshTokenInProgress = true;
      return this.updateToken().pipe(
        tap(() => {
          this.refreshTokenInProgress = false;
          this.tokenRefreshedSource.next(true);
        }),
        catchError(err => {
          this.refreshTokenInProgress = false;
          return throwError(() => err);
        })
      );
    }
  }

  private updateToken(): Observable<unknown> {
    return new Observable(observer => {
      this.keycloakAuth?.updateToken(20).then(
        value => {
          observer.next(value);
          observer.complete();
        },
        () => {
          observer.error();
        }
      );
    });
  }

  getUsername(): string | undefined {
    return this.userProfile?.firstName + ' ' + this.userProfile?.lastName;
  }

  getUserId(): string | undefined {
    return this.userProfile?.id;
  }
}
